import { useEffect } from 'react'
import Nav from './components/Nav'
import Realization from './components/Realization'
import Footer from './components/Footer'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/colors.css'
import './App.css'

function App() {

    useEffect(() => {

        window.onbeforeunload = function () {
            window.scrollTo(0, 0)
        }

    }, [])

    return (

        <div className="App">
            <Nav />
            <Realization />
            <Footer />
        </div>

    )

}

export default App
