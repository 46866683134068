import { isBrowser } from 'react-device-detect'
import { Carousel } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import '../styles/Realization.css'
import carousel1photo1 from '../assets/Série_1A.png'
import carousel1photo2 from '../assets/Série_1B.png'
import carousel2photo1 from '../assets/Série_2A.png'
import carousel2photo2 from '../assets/Série_2B.png'
import carousel3photo1 from '../assets/Série_3A.png'
import carousel3photo2 from '../assets/Série_3B.png'
import carousel4photo1 from '../assets/Série_4A.png'
import carousel4photo2 from '../assets/Série_4B.png'
import carousel5photo1 from '../assets/Série_5A.png'
import carousel5photo2 from '../assets/Série_5B.png'
import carousel5photo3 from '../assets/Série_6A.png'
import carousel5photo4 from '../assets/Série_6B.png'

function Realization() {

    const carouselData = [
        {
            title: "Maison Lou",
            photos: [
                { photo: carousel1photo1 },
                { photo: carousel1photo2 }
            ]
        }, {
            title: "Patrizia Pepe",
            photos: [
                { photo: carousel2photo1 },
                { photo: carousel2photo2 }
            ]
        }, {
            title: "Thalie",
            photos: [
                { photo: carousel3photo1 },
                { photo: carousel3photo2 }
            ]
        }, {
            title: "Finis Terrae",
            photos: [
                { photo: carousel4photo1 },
                { photo: carousel4photo2 }
            ]
        }, {
            title: <br />,
            photos: [
                { photo: carousel5photo1 },
                { photo: carousel5photo2 }
            ]
        }, {
            title: <br />,
            photos: [
                { photo: carousel5photo3 },
                { photo: carousel5photo4 }
            ]
        }

    ]

    return (

        <Fade bottom>
            <div id="realization" className='sectionLight'>
                {carouselData.map((itemCarousel, index) => {
                    return (
                        <Fade left={index % 2 === 0 ? true : false} right={index % 2 === 1 ? true : false}>
                            <Carousel interval={5000}>
                                {itemCarousel.photos.map((itemPhoto, index) => {
                                    return (
                                        <Carousel.Item key={index}>
                                            <img src={itemPhoto.photo} alt="" height={isBrowser ? '600px' : '250px'} />
                                        </Carousel.Item>
                                    )
                                })}
                            </Carousel>
                            <div className='sectionLightTitle'>{itemCarousel.title}</div>
                        </Fade>
                    )
                })}
            </div>
        </Fade>

    )

}

export default Realization
