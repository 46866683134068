import { BrowserView, MobileView } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import '../styles/Footer.css'

function Footer() {

    return (


        <div id="footer">
            <div id="contact">
                <BrowserView>
                    <div className='sectionDarkTitle'>Me contacter</div>
                    <div className='infoItem'>
                        <FontAwesomeIcon icon={faEnvelope} id='infoIcon' />
                        <div className='infoText'>contact@mitsab.fr</div>
                    </div>
                    <div className='infoItem'>
                        <a href="https://www.instagram.com/mitsab" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} id='infoIconInstagram' />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61551067074509" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} id='infoIconFacebook' />
                        </a>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className='sectionDarkTitle'>Me contacter</div>
                    <div className='infoItem'>
                        <FontAwesomeIcon icon={faEnvelope} id='infoIcon' />
                        <div className='infoTextSmartphone'>contact@mitsab.fr </div>
                    </div>
                    <div className='infoItem'>
                        <a href="https://www.instagram.com/mitsab" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} id='infoIconInstagramSmartphone' />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61551067074509" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} id='infoIconFacebookSmartphone' />
                        </a>
                    </div>
                </MobileView>
            </div>
        </div>

    )

}

export default Footer
