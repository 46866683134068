import { useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faEnvelope, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import Fade from 'react-reveal/Fade'
import '../styles/Nav.css'

function Nav() {

    const [displaySmartphoneMenu, setDisplaySmartphoneMenu] = useState(false)

    return (

        <Fade bottom>
            <nav id='nav'>
                <BrowserView>
                    <Row>
                        <Col sm={8}>
                            <div id='navLeft' onClick={() => window.scrollTo(0, 0)}>
                                MITSAB BOOK
                                <br />
                                Photographie
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div id='navRight'>
                                <a href="https://www.instagram.com/mitsab" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} id='navLinkedinIcon' /></a>
                                <a href="https://www.facebook.com/profile.php?id=61551067074509" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} id='navFacebookIcon' /></a>
                                <div className='navLink'>
                                    <a href={'#contact'}><FontAwesomeIcon icon={faEnvelope} className='navLinkIcon' />{"Contact"}</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </BrowserView>
                <MobileView>
                    <div id='navSmartphone'>
                        <div id='navSmartphoneCenter' onClick={() => window.scrollTo(0, 0)}>
                            MITSAB BOOK
                            <br />
                            Photographie
                        </div>
                        <div id='navSmartphoneRight'>
                            {!displaySmartphoneMenu ?
                                <FontAwesomeIcon icon={faBars} onClick={() => { setDisplaySmartphoneMenu(true); window.scrollTo(0, 600); document.getElementById("nav").style.height = "250px" }} />
                                :
                                <div onClick={() => { setDisplaySmartphoneMenu(false); document.getElementById("nav").style.height = "60px" }}>
                                    <FontAwesomeIcon icon={faXmark} id='navSmartphoneCloseIcon' />
                                </div>
                            }
                        </div>
                    </div>
                    <Fade top when={displaySmartphoneMenu}>
                        <div id='navSmartphoneLinks'>
                            <div className='navSmartphoneLink'>
                                <a href={'#contact'}><FontAwesomeIcon icon={faEnvelope} className='navLinkIcon' />{"Contact"}</a>
                            </div>
                            <a href="https://www.instagram.com/mitsab" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} id='navSmartphoneLinkedinIcon' /></a>
                            <a href="https://www.facebook.com/profile.php?id=61551067074509" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} id='navSmartphoneFacebookIcon' /></a>
                        </div>
                    </Fade>
                </MobileView>
            </nav>
        </Fade >

    )

}

export default Nav
